import React from "react"

import LinkWrapper from "../link-wrapper"

const Button = ({ to, text, className = "" }) => {
  return (
    <LinkWrapper className={`button-class ${className}`} to={to}>
      {text}
    </LinkWrapper>
  )
}

export default Button
