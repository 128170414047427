import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"

import BlurLoader from "../reusable-components/loaders/blur-loader"
import LinkWrapper from "../reusable-components/link-wrapper"
import SubmitButton from "../reusable-components/buttons/submit-button"

import { getUser } from "../../context/auth/auth-functions"
import AuthContext from "../../context/auth/auth-context"

// ======================
//       COMPONENT
// ======================
const WelcomeToMyAccount = () => {
  const [loading, setLoading] = useState(true)
  const [userName, setUserName] = useState("")
  const { performLogout } = useContext(AuthContext)

  // Fetch address from WC
  useEffect(() => {
    setLoading(false)
    const { user_display_name } = getUser()
    setUserName(user_display_name)
    return
  }, [])

  return (
    <BlurLoader loading={loading} message="Updating">
      <WelcomeToMyAccountStyling>
        <h3>
          Welcome <UserNameStyling>{userName} </UserNameStyling>to your Euro
          Bath & Tile account page
        </h3>
        <p>
          In brief, from here you can view your order history and edit your
          shipping details.
        </p>
        <p>
          If you have any problems with your order, please
          <StyledLinkWrapper to="/contact-us/"> contact</StyledLinkWrapper> us
          immediatly.
        </p>
        <LogoutStyling>
          {`Not ${userName} ?`}
          <SubmitButton onClick={() => performLogout("/")} text="Logout" />
        </LogoutStyling>
      </WelcomeToMyAccountStyling>
    </BlurLoader>
  )
}

export default WelcomeToMyAccount

// ======================
//         STYLES
// ======================
const StyledLinkWrapper = styled(LinkWrapper)`
  font-weight: 600;
`
const WelcomeToMyAccountStyling = styled.div`
  h3 {
    text-transform: none;
  }
  p:last-of-type {
    margin: 0;
  }
`
const UserNameStyling = styled.span`
  font-weight: 600;
`
const LogoutStyling = styled.div`
  align-items: center;
  padding-top: 3rem;
  button {
    margin-top: 1rem;
  }
`
