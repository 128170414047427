import React, { useLayoutEffect, useContext } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import AuthContext from "../../context/auth/auth-context"

import Layout from "../theme/layout"
import SEO from "../theme/header/seo"

import AccountNavigationPanel from "./account-navigation-panel"

const AccountWrapper = ({ children, location, accountStep = "" }) => {
  const { loggedInState } = useContext(AuthContext)

  // Check if user is not logged in and show their email address
  useLayoutEffect(() => {
    if (loggedInState === false) {
      navigate("/login/")
    }
  }, [loggedInState])

  const title = "My Account Information"

  return (
    <Layout location={location}>
      <SEO title={title} location={location} yoast={{}} />
      <StyledH1>My Account</StyledH1>
      <AccountNavigationPanel accountStep={accountStep} />
      {loggedInState && children ? <div>{children}</div> : null}
    </Layout>
  )
}

export default AccountWrapper

// ===============
//     STYLES
// ===============

const StyledH1 = styled.h1`
  margin: 0;
`
