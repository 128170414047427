import React from "react"

import AccountWrapper from "../../components/account/account-wrapper"
import WelcomeToMyAccount from "../../components/account/welcome-to-my-account"

const MyAccount = ({ location }) => {
  return (
    <AccountWrapper location={location} accountStep="my-account">
      <WelcomeToMyAccount location={location} />
    </AccountWrapper>
  )
}

export default MyAccount
