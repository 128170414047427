import React from "react"
import styled from "styled-components"

import LinkButton from "../reusable-components/buttons/link-button"

const AccountNavigationPanel = ({ accountStep }) => {
  return (
    <AccountNavigationPanelStyling>
      <LinkButton
        to="/my-account/"
        text="My Account"
        className={accountStep == "my-account" ? "button-active" : "inactive"}
      />
      <LinkButton
        to="/my-account/billing-details/"
        text="Billing Details"
        className={
          accountStep == "billing-details" ? "button-active" : "inactive"
        }
      />
      <LinkButton
        to="/my-account/shipping-details/"
        text="Shipping Details"
        className={
          accountStep == "shipping-details" ? "button-active" : "inactive"
        }
      />
      <LinkButton
        to="/my-account/order-history/"
        text="Orders and Quotes"
        className={
          accountStep == "order-history" ? "button-active" : "inactive"
        }
      />
    </AccountNavigationPanelStyling>
  )
}

export default AccountNavigationPanel

// ===============
//     STYLES
// ===============
const AccountNavigationPanelStyling = styled.div`
  display: flex;
  padding: 3rem 0;
  justify-content: space-between;
  text-transform: uppercase;
  a {
    margin-bottom: 10px;
    width: 100%;
  }
  a + a {
    margin-left: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    a + a {
      margin-left: 0;
    }
  }
`
